import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import POTY from "../images/POTY_Delivery_2023.svg"
import CSAT from "../images/CSAT_Badge.svg"

import PSA_I from "../images/PSA_ImplemConsult.png"
import PSA_S from "../images/PSA_SysAdmin.png"
import PSA_6 from "../images/PSA6.png"

import MANAGED from "../images/Managed.png"


import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"

const HeroDiv = styled(BackgroundImage)`
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center bottom;
    background-color: var(--white);
    height: 600px;
    margin-top: 80px;

    @media only screen and (max-width: 1140px) {
        height: 570px;
    }

    @media only screen and (max-width: 767px) {
        height: 750px;
    }

    @media only screen and (max-width: 400px) {
        height: 850px;
    }

    .carousel.carousel-slider {
        overflow: visible;
    }
    .carousel .slide {
        width: 100%;
        background: none;
        text-align: left;

        img.csat {
            width: 148px;
            margin-right: 20px;
            @media only screen and (max-width: 767px) {
                margin-left: 20px;
                margin-right: 40px;
            }
        }
    
        img.poty {
            width: 102px;
            margin-right: 20px;
        }

    }
    @media only screen and (min-width: 767px) {
        .carousel .control-dots {
            max-width: 1110px;
            text-align: left;
            margin: auto;
            position:static;
            margin-top: -30px;
        }
    }   
    
    @media only screen and (max-width: 767px) {
        .carousel .control-dots {
            bottom: -20px;
        }
    }
`;

const Flex = styled.div `
    max-width: 1110px;
    margin: 118px auto 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: start;

    @media only screen and (max-width: 1140px) {
        margin: 46px 12px;
        align-items: center;

        h1 {
            font-size: 40px;
            line-height: 44px;
        }
    }

    @media only screen and (max-width: 767px) {
       flex-direction: column-reverse;
       align-items: center;
    }
`;

const HeroTextDiv = styled.div `
    width: 46%;
    margin-top: -40px;

    @media only screen and (max-width: 1140px) {
        margin-top: 0px;
    }

    @media only screen and (max-width: 767px) {
        width: 100%;

        h1 {
            width: 90%;
        }
    }

    p.hero {
        max-width: 90%;
        @media only screen and (max-width: 1140px) {
            line-height: 26px;
            margin-top: 0;
            margin-bottom: 30px;
        }
    }
`;

const HeroImageDiv = styled.div `
    width: 48%;

    @media only screen and (max-width: 767px) {
        width: 100%;
        max-width 324px;
    }
`;

const HeroContentDiv = styled.img`
    position: relative;
    overflow: hidden;
    width: 590px;
    height: auto;
    float: right;
    margin-top: -70px;
    margin-right: 10px;

    @media only screen and (max-width: 1140px) {
        width: 100%;
        height: auto;
        margin-top: -24px;

    }

    @media only screen and (max-width: 767px) {
        max-width 340px;
        margin-top: -5px;
        float: none;
    }
`;

const query = graphql`
    query {
        sanityHero(title: { eq: "Homepage" }) {
            title
           heading
            subline
            video_url
            background_color
            image {
                asset {
                    url
                }
            }
            background_image {
                asset {
                    fluid(maxWidth: 1920) {
                        ...GatsbySanityImageFluid
                    }
                }
              }
          }
    }
  `

export default () => (
    <StaticQuery
        query={query}
        render={data => {
            return (
                <HeroDiv fluid={data.sanityHero.background_image.asset.fluid}>
                    <Carousel showThumbs={false} showIndicators={true} showArrows={false} showStatus={false} autoPlay={true} interval={5000} infiniteLoop={true}>
                            <Flex>
                            <HeroTextDiv className="hero">
                                <h1>Trusted ERP <br/> experts on the Salesforce platform</h1>
                                <img className="csat" src={CSAT} alt="4.9 / 5 CSAT Score"/>
                                <img className="poty" src={POTY} alt="Partner Of The Year in North America"/>
                            </HeroTextDiv>
                            <HeroImageDiv>
                                <HeroContentDiv src={data.sanityHero.image.asset.url} alt="Man wearing suit looking at graph, man wearing red shirt sitting crossed legged on the ground with his laptop, woman in suit hold extremely large magnifying glass."></HeroContentDiv>
                            </HeroImageDiv>
                            </Flex>
                            
                            <Flex>
                            <HeroTextDiv className="hero">
                                <h1>Professional services with your customer at the center</h1>
                                <img className="poty" src={PSA_I} alt="4.9 / 5 CSAT Score"/>
                                <img className="poty" src={PSA_S} alt="Partner Of The Year in North America"/>
                            </HeroTextDiv>
                            <HeroImageDiv>
                                <HeroContentDiv src={PSA_6} className="psa" alt="People looking at different graphs and charts"></HeroContentDiv>
                            </HeroImageDiv>
                            </Flex>

                            <Flex>
                            <HeroTextDiv className="hero">
                                <h1> Managed Services for Certinia, Rootstock and Salesforce</h1>
                                <a href="services/managed-services">Learn more</a>
                            </HeroTextDiv>
                            <HeroImageDiv>
                                <HeroContentDiv src={MANAGED} className="managed" alt="Man in center of a mind map."></HeroContentDiv>
                            </HeroImageDiv>
                            </Flex>
                    </Carousel>
                </HeroDiv>
            )
        }}
    />
)