import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import HeroContentBackground from "../images/HERO_RS.png"
import RootStockSVG from "../images/rootstock_partner.svg"

const HeroDiv = styled.div`
    #rootStock {
        width: 247px;
        margin-bottom: 30px;
    }
`;

const Flex = styled.div `
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    @media only screen and (max-width: 1100px) {
        flex-direction: column-reverse;
        max-width: 100%;
        overflow: hidden;
    }
`

const HeroTextDiv = styled.div `
    width: 50%;
    display: flex;
    justify-content: flex-end;

    > div {
        padding: 80px;
        max-width: 509px;
    }

    @media only screen and (max-width: 1100px) {
        width: 100%;
        justify-content: left;
     }

     @media only screen and (max-width: 640px) {
        > div {
            padding: 60px 25px;
        }
    }

    h2 {
        margin-bottom: 24px;
    }

    ul {
        columns: 2;
        list-style-type: none;
        margin-left: 0;
        font-weight: 500;
        padding-top: 10px;

        @media only screen and (max-width: 640px) {
            columns: 1;
        }
    }
`;

const HeroImageDiv = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-start;
    background: #D7DFEF;
    
    @media only screen and (max-width: 1100px) {
        width: 100%;
        padding: 50px 20px;
        justify-content: center;
     }

     @media only screen and (max-width: 640px) {
        padding: 40px 10px;
    }
`;

const HeroContentDiv = styled.img`
    max-width: 560px;
    height: auto;
    margin: auto 80px;

    @media only screen and (max-width: 1100px) {
        width: 100%;
        margin: 50px 20px;
    }

    @media only screen and (max-width: 640px) {
        max-width: 328px;
        margin: 0;
    }
`;

const LearnMoreButton = styled(Link)`
    text-transform: uppercase;
    font-weight: 600;
    line-height: 16px;

    svg {
        padding-left: 10px;
    }
`;

const chrevonSVG = () => (
    <svg width="9px" height="14px" viewBox="0 0 9 14" version="1.1">
        <title>Chevron</title>
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
            <g id="Link-/-LEARN-MORE" transform="translate(-118.000000, -2.000000)" stroke="#23C0F5" stroke-width="2">
                <polyline id="Chevron" transform="translate(122.000000, 9.000000) rotate(-90.000000) translate(-122.000000, -9.000000) " points="116 6 122.238234 12 128 6"></polyline>
            </g>
        </g>
    </svg>
);

const Rootstock = () => (
    <HeroDiv>
        <Flex>
        <HeroTextDiv>
            <div>
            <img src={RootStockSVG} alt="Rootstock Partners Logo" id="rootStock"/>
            <h2 className="h1">Rootstock</h2>
            <p>Ladd Partners offers  implementation services, customization and support for the Rootstock ERP to help customers with deeper inventory management and manufacturing needs.</p>
            <ul>
                <li>Sales</li>
                <li>Inventory Management</li>
                <li>Sales Order Processing</li>
                <li>Engineering</li>
                <li>Supply Chain</li>
                <li>Production Planning</li>
                <li>Financials</li>
                <li>Analytics</li>
            </ul>
            <LearnMoreButton to="/services/rootstock">Learn More {chrevonSVG()}</LearnMoreButton>
            </div>
        </HeroTextDiv>
        <HeroImageDiv>
            <HeroContentDiv src={HeroContentBackground} alt="Three people looking at a very large screen with mutliple graphs"></HeroContentDiv>
        </HeroImageDiv>
        </Flex>
    </HeroDiv>
)

export default Rootstock