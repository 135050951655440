import React from "react"
import Hero from "../components/hero"
import Testimonials from "../components/testimonials"
import Services from "../components/services"
import Financialforce from "../components/financialforce"
import Rootstock from "../components/rootstock"
import Salesforce from "../components/salesforce"
import Partners from "../components/partners"

import "../assets/index.css"
import Layout from "../layouts/layout"

export default () => (
    <Layout title="Ladd Partners">
        <Hero/>
        <Services/>
        <Financialforce/>
        <Rootstock/>
        <Salesforce/>
        <Testimonials/>
        <Partners alignment="center" size="48px"  mobileSize="36px" mobileMargin="24px" case="uppercase" fontweight="600" color="#6A7C92" margintop="60px" maxwidth="700px" psize="20px"/>
    </Layout>
)
