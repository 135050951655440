import React from "react"
import styled from "styled-components"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { StaticQuery, graphql } from "gatsby"

const TestimonialDiv = styled.div`
    position: relative;

    @media only screen and (max-width: 767px) {
        width: 80%;
        margin: auto;
    }
`
const WrapperDiv = styled.div`
    max-width: 1084px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 76px 20px 80px;
    font-weight: 500;
    color: #6A7C92;
    border-top: 1px solid #979797;
    border-bottom: 1px solid #979797;
    margin-bottom: 160px;
    
    @media only screen and (max-width: 767px) {
        padding: 60px 15px 100px;
        margin-bottom: 100px;

        h2 {
            text-align: center;
        }
    }

    svg {
        margin-left: 20px;
    }

    > div {
        width: 100%;
    }

    .carousel.carousel-slider {
        overflow: initial;
    }

    .carousel .slide {
        width: 100%;
        background: #FFF;
    }

    .carousel.carousel-slider .control-arrow {
        width: 66px;
        height: 66px;
        background: none;
        opacity: 1;
        margin-top: 10px;

        @media only screen and (max-width: 920px) {
            display: none;
        }
    }

    .carousel.carousel-slider .control-next {
        background-image: url(/NextArrow.svg);
        background-repeat: no-repeat;

        &:before {
            border: none;
        }

        &:hover {
            background: none;
            background-image: url(/NextArrow-hover.svg);
        }
    }

    .carousel.carousel-slider .control-prev {
        background-image: url(/PrevArrow.svg);
        background-repeat: no-repeat;

        &:before {
            border: none;
        }

        &:hover {
            background: none;
            background-image: url(/PrevArrow-hover.svg);
        }
    }
`;

const TestimonialsSlideDiv = styled.div`
    text-align: center;
    width: 100%;
    max-width: 790px;
    margin: auto;
    cursor: drag;

    @media only screen and (max-width: 720px) {
        user-select: none;
    }
`;

const query = graphql`
    query {
        allSanityTestimonial(sort: {fields: [order], order: ASC}) {
            totalCount
            edges {
              node {
                id
                customer_name
                title
                description
              }
            }
          }
      }
  `

const Testimonial = ({testimonial}) => (
    <TestimonialsSlideDiv>
        <p className="margin-bottom-10">"{testimonial.description}"</p>
        <p className="testimonial-name margin-bottom-0"><strong>{testimonial.customer_name}</strong></p>
        <p className="testimonial-title">{testimonial.title}</p>
    </TestimonialsSlideDiv>
)

function getTestimonial(data) {
    const testimonialArray = [];
    data.allSanityTestimonial.edges.forEach(testim => {
        testimonialArray.push(<Testimonial key={testim.node.id} testimonial={testim.node}></Testimonial>)
    });

    return testimonialArray;
}

export default () => (
    <StaticQuery
        query={query}
        render={data => {
            return (
                <>
                <TestimonialDiv>
                    <WrapperDiv>
                        <h2 className="margin-bottom-40">See what our customers say about us…</h2>
                        <Carousel showThumbs={false} showIndicators={false} showArrows={true} showStatus={false} emulateTouch={true} autoPlay={true} interval={5000} infiniteLoop={true}>
                            {getTestimonial(data)}
                        </Carousel>
                    </WrapperDiv>
                </TestimonialDiv>
                </>
            )
        }}
    />
)