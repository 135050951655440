import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"

const WrapperDiv = styled.div`
    max-width: 1140px;
    margin: 50px auto 135px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 20px;

    @media only screen and (max-width: 1140px) {
        margin-top: 45px;
        margin-bottom: 173px;
    }

    h2, > p {
        display: block;
        width: 100%;
    }

    .RMCCDesc{
        margin-top: 70px;

        @media only screen and (max-width: 720px) {
            margin-top: 90px;
        }
    }

    &.left {
        margin: 0px auto 100px;
    }
`;

const PartnersContainerDiv = styled.div`
    width: calc(100%/3);
    max-width: 310px;
    margin-left: 85px;
    margin-top: 45px;
    margin-bottom: 0;

    &#RMC {
        margin-top: 0!important;
    }

    &:first-of-type, &:nth-of-type(4),  &:nth-of-type(7) {
        margin-left: 0;
    }

    &:first-of-type, &:nth-of-type(2), &:nth-of-type(3) {
        margin-bottom: 20px;
    }

    @media only screen and (max-width: 1140px) {
        width: 50%;
        margin-bottom: 0px!important;
        max-width: none;
        margin-left: 0;

        > div {
            padding: 0
        }
    }

    @media only screen and (max-width: 720px) {
        width: 100%;
        margin-bottom: 50px;
    }
`;

const Flex = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const PartnersContentDiv = styled.div`
    height: 100%;
`;

const PartnersContentIconDiv = styled.div`
    margin-bottom: 20px;
    height: 50px;

    .gatsby-image-wrapper {
        width: 100%!important;
    }

    img {
        width: auto !important;
        height: 42px !important;
    }

    &.ZenKraftImg img {
        height: 60px!important;
        margin-top: -10px;
    }

    &.RMCCImg img {
        height: 109px!important;
    }

    @media only screen and (max-width: 720px) {
        height: 30px;
        margin-bottom: 30px;
    }
`

const query = graphql`
    query {
        allSanityPartner(sort: {fields: [order], order: ASC}) {
            totalCount
            edges {
              node {
                id
                title
                description
                order
                image {
                    asset {
                        url
                    }
                }
              }
            }
          }
      }
  `

const Partners = ({partner}) => (
    <PartnersContainerDiv className={partner.title}>
        <PartnersContentDiv >
            <PartnersContentIconDiv className={partner.title + 'Img'}>
                <img src={partner.image.asset.url} alt="Partner Logo - {partner.name}"/>
            </PartnersContentIconDiv>
            <p className={partner.title + 'Desc'}>{partner.description}</p>
        </PartnersContentDiv>
    </PartnersContainerDiv>
)

function getPartner(data) {
    const partnerArray = [];
    data.allSanityPartner.edges.forEach(item => {
        partnerArray.push(<Partners key={item.node.id} partner={item.node}></Partners>)
    });

    return partnerArray;
}

export default (styleData) => (
    <StaticQuery
        query={query}
        render={data => {
            return (
                <>
                <WrapperDiv styleData={styleData} className={styleData.alignment === 'left' ? 'left' : ''}>
                    <h2 className={styleData.alignment === 'left' ? 'margin-top-100 margin-bottom-20' : 'h1 text-center margin-bottom-20'}>Partners</h2>
                    <p className={styleData.alignment === 'left' ? '' : 'text-center'}>Beyond Salesforce, Rootstock and Certinia, we also implement, integrate and partner with the following companies:</p>
                    <Flex>{getPartner(data)}</Flex>
                </WrapperDiv>
                </>
            )
        }}
    />
)
